import util from '@bmp-vue/core/api/util'

const controller = 'Requisicao';
export default Object.assign({}, util.GetCrud(controller, ['getall']), {
    GetAllRrc0019ByCodigoRequisicao: function(codigoRequisicao) {
        return util.Axios.GetAll(controller + '/' + codigoRequisicao + '/Rrc0019')
    },
    GetAllRrc0020ByCodigoRequisicao: function(codigoRequisicao){
        return util.Axios.GetAll(controller + '/' + codigoRequisicao + '/Rrc0020')
    },
    GetAllRrc0021ByCodigoRequisicao: function(codigoRequisicao){
        return util.Axios.GetAll(controller + '/' + codigoRequisicao + '/Rrc0021')
    },
    GetAllRrc0035ByCodigoRequisicao: function(codigoRequisicao){
        return util.Axios.GetAll(controller + '/' + codigoRequisicao + '/Rrc0035')
    },
    GetAllGen0001ByCodigoRequisicao: function(codigoRequisicao){
        return util.Axios.GetAll(controller + '/' + codigoRequisicao + '/Gen0001')
    },
    GetRespostaSincronaByCodigo: function(codigoRequisicao) {
        return util.Axios.GetAll(controller + '/' + codigoRequisicao + '/RespostaSincrona')
    },
    DownloadRelatorio: function(codigoRequisicao, layout){
        return util.Axios.GetAll(`${controller}/${codigoRequisicao}/${layout}/DownloadRelatorio`)
    },
    DownloadRetorno: async function(codigo, nomeArquivo, layout) {
        const data = await util.Axios.GetAll(
            controller + `/${codigo}/${layout}/retorno`
        );

        if(data.sucesso !== undefined && data.sucesso == false){

            if(data.mensagem === 'The specified key does not exist.'){
                data.mensagem = 'O arquivo solicitado não foi encontrado no S3. Ele pode ainda estar em processamento. Por favor, tente novamente mais tarde.'
            }

            throw new Error(data.mensagem)
        }

        const url = window.URL.createObjectURL(new Blob([JSON.stringify(data, null, 4)]));
        const link = document.createElement('a');
        link.href = url;

        link.setAttribute('download', nomeArquivo + '_response_retorno.json');
        document.body.appendChild(link);

        link.click();
    },
    DownloadRespostaSincrona: async function(codigo, nomeArquivo, layout) {
        const data = await util.Axios.GetAll(
            controller + `/${codigo}/${layout}/RespostaSincrona`
        );

        if(data.sucesso !== undefined && data.sucesso == false){

            if(data.mensagem === 'The specified key does not exist.'){
                data.mensagem = 'O arquivo solicitado não foi encontrado no S3. Ele pode ainda estar em processamento. Por favor, tente novamente mais tarde.'
            }

            throw new Error(data.mensagem)
        }

        const url = window.URL.createObjectURL(new Blob([JSON.stringify(data, null, 4)]));
        const link = document.createElement('a');
        link.href = url;

        link.setAttribute('download', nomeArquivo + '_response_sincrono.json');
        document.body.appendChild(link);

        link.click();
    },
    Reprocessar: function(codigoRequisicao, layout) {
        return util.Axios.Post(controller + '/' + codigoRequisicao + '/' + layout + '/Reprocessar', {})
    },
});